import { jwtDecode } from 'jwt-decode';
import { create } from 'zustand';

import { queryClient } from '@/config/query-client';
import { LS_ACCESS_TOKEN_KEY, LS_REFRESH_TOKEN_KEY } from '@/constants/auth';
import { AccessRefreshToken } from '@/types';
import { fetchApi } from '@/utils/api';

export type User = {
  id: string;
  name: string;
  email: string;
  isAdmin: boolean;
  isAccountAdmin: boolean;
  contactPluggy: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
};

type State = {
  isInitialized: boolean;
  isLogged: boolean;
  user?: User | null;
};

type Action = {
  init: () => void;
  logout: () => void;
  login: (accessToken: string, refreshToken: string) => void;
};

export const useAuthStore = create<State & Action>((set, get) => ({
  /* Initial state */
  isInitialized: false,
  isLogged: false,
  user: null,

  /* Actions */
  init: async () => {
    const accessToken = localStorage.getItem(LS_ACCESS_TOKEN_KEY);
    const refreshToken = localStorage.getItem(LS_REFRESH_TOKEN_KEY);
    if (!accessToken && !refreshToken) {
      return set({ isLogged: false, user: null, isInitialized: true });
    }

    if (!accessToken) {
      try {
        const accessRefreshTokens = await fetchApi<AccessRefreshToken>(
          '/auth/sign-in/refresh',
          'POST',
          null,
          { refreshToken },
          { withoutAuth: true },
        );

        return get().login(
          accessRefreshTokens.accessToken,
          accessRefreshTokens.refreshToken,
        );
      } catch {
        return set({ isLogged: false, user: null, isInitialized: true });
      }
    }

    try {
      await fetchApi('/auth/check', 'GET');
    } catch {
      return set({ isLogged: false, user: null, isInitialized: true });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload: Record<string, any> = jwtDecode(accessToken);

    return set({
      isLogged: true,
      user: {
        id: payload.sub,
        name: payload.name,
        email: payload.email,
        isAdmin: payload.isAdmin,
        isAccountAdmin: payload.isAccountAdmin,
        contactPluggy: {
          firstName: payload.contactPluggy.firstName,
          lastName: payload.contactPluggy.lastName,
          email: payload.contactPluggy.email,
          phone: payload.contactPluggy.phone,
        },
      },
      isInitialized: true,
    });
  },

  logout: () =>
    set(() => {
      queryClient.removeQueries();
      localStorage.removeItem(LS_ACCESS_TOKEN_KEY);
      localStorage.removeItem(LS_REFRESH_TOKEN_KEY);

      return { isLogged: false, user: null };
    }),

  login: (accessToken, refreshToken) =>
    set(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const payload: Record<string, any> = jwtDecode(accessToken);

      localStorage.setItem(LS_ACCESS_TOKEN_KEY, accessToken);
      localStorage.setItem(LS_REFRESH_TOKEN_KEY, refreshToken);

      return {
        isLogged: true,
        user: {
          id: payload.sub,
          name: payload.name,
          email: payload.email,
          isAdmin: payload.isAdmin,
          isAccountAdmin: payload.isAccountAdmin,
          contactPluggy: {
            firstName: payload.contactPluggy.firstName,
            lastName: payload.contactPluggy.lastName,
            email: payload.contactPluggy.email,
            phone: payload.contactPluggy.phone,
          },
        },
      };
    }),
}));
