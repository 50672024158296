export type HTTPMethod =
  | 'CONNECT'
  | 'DELETE'
  | 'GET'
  | 'HEAD'
  | 'OPTIONS'
  | 'PATCH'
  | 'PUT'
  | 'POST'
  | 'TRACE';

export class ApiError extends Error {
  statusCode: number;
  error?: string;
  constructor(errorMessage: string, statusCode: number, error?: string) {
    super(errorMessage);
    this.error = error;
    this.statusCode = statusCode;
  }
}

export type AccessRefreshToken = {
  accessToken: string;
  refreshToken: string;
};
